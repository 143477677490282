import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { Animations } from '@styles/animation/animations';

@Component({
  selector: 'recrewt-auto-type-speech-bubble',
  templateUrl: './auto-type-speech-bubble.component.html',
  styleUrls: ['./auto-type-speech-bubble.component.scss'],
  animations: [Animations.fadeIn],
})
export class AutoTypeSpeechBubbleComponent implements OnInit, OnChanges {
  @Input() text: string | null = null;

  @Input() imageSrc: string | null = null;

  @Input() buttonTemplate?: TemplateRef<any>;

  @Input() dismissable: boolean = true;

  @Input() hoverHide: boolean = true;

  @Output() dismiss: EventEmitter<void> = new EventEmitter();

  showImage = false;

  autoTypedText = '';

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.start();
  }

  start() {
    this.autoTypedText = '';
    if (this.imageSrc) {
      this.showImage = true;
      setTimeout(() => {
        this.type();
      }, 500);
    } else {
      this.type();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes['text']?.currentValue && !changes['text']?.firstChange) {
      this.start();
    }
  }

  hideUntilLater() {
    this.text = null;
    this.dismiss.emit();
  }

  private type() {
    if (!this.text) {
      return;
    }

    const nextChar = this.text[this.autoTypedText.length];
    if (!nextChar) {
      return;
    }

    this.autoTypedText += nextChar;
    this.cdr.detectChanges();
    if (this.autoTypedText.length < this.text.length) {
      setTimeout(() => this.type(), 10);
    }
  }
}
