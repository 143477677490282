import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '@data/user/user.model';
import { SaveState } from '@data/save-state/save-state.model';
import { appConfig } from '../../../app.config';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private url = `${environment.apiUrl}/users`;

  constructor(private http: HttpClient) {}

  get(id: string): Observable<[User, SaveState] | undefined> {
    return this.http.get<[User, SaveState]>(`${this.url}/${appConfig.app.name}/${id}`);
  }

  update(changes: Partial<User>) {
    return this.http.put(`${this.url}`, changes);
  }

  create(): Observable<[User, SaveState]> {
    return this.http.post<[User, SaveState]>(`${this.url}`, { platform: appConfig.app.name });
  }

  restartStory(id: string): Observable<[User, SaveState]> {
    return this.http.post<[User, SaveState]>(`${this.url}/${id}/restart_story`, {});
  }
}
