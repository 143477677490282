import { createState, Store } from '@ngneat/elf';
import {
  getActiveEntity,
  selectActiveEntity,
  selectActiveId,
  setActiveId,
  updateEntities,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';
import { Injectable } from '@angular/core';
import { EntityRepository } from '@data/entity-repository';
import { MatchData, SaveState } from '@data/save-state/save-state.model';
import { SaveStateService } from '@data/save-state/save-state.service';
import { Observable, tap } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';

const { state, config } = createState(
  withEntities<SaveState>(),
  withActiveId(),
  withRequestsCache(),
);

const saveStateStore = new Store({ name: 'save-state', state, config });

@Injectable({ providedIn: 'root' })
export class SaveStateRepository extends EntityRepository<SaveState> {
  readonly selectedSaveState$: Observable<SaveState | null> = this.requireStore().pipe(
    selectActiveEntity(),
  );

  readonly selectMatchData$: Observable<MatchData> = this.selectedSaveState$.pipe(
    filter((ss) => !!ss),
    map((ss) => (ss as SaveState).matchData),
  );

  readonly selectedSaveStateId$ = this.requireStore().pipe(selectActiveId());

  protected constructor(saveStateService: SaveStateService) {
    super(saveStateService);
  }

  querySelectedSaveState() {
    return this.store.query(getActiveEntity()) as SaveState | null;
  }

  selectSaveState(id: string) {
    this.store.update(setActiveId(id));
  }

  finishStory(id: string, entity: Partial<SaveState>) {
    return this.service.update(id, { ...entity, storyDone: true }).pipe(
      tap((x) => {
        this.store.update(updateRequestCache(this.store.name + id), updateEntities(id, x));
      }),
    );
  }

  protected override requireStore(): Store {
    return saveStateStore;
  }
}
