import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';

export interface InputDialogData {
  title: string;
  message: string;
  error: string;
  label: string;
  validator?: (control: AbstractControl) => ValidationErrors | null;
  action?: string;
  value?: string | number;
}

@Component({
  selector: 'recrewt-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss'],
})
export class InputDialogComponent implements OnInit {
  form!: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<InputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputDialogData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit(): void {
    const { value, valid } = this.form;
    if (valid) {
      this.dialogRef.close(value.name);
    }
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: [
        this.data.value ?? null,
        [Validators.required, this.data.validator ?? Validators.required],
      ],
    });
  }
}
