import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iterable',
})
export class IterablePipe implements PipeTransform {
  /**
   * Converts a number into an array that mimics what a iteration over a number would behave like.
   * Example:  3 -> [0, 1, 2].
   *
   * @param value the value to convert.
   * @return the iterable representation of the number
   */
  transform(value: number): number[] {
    return Array(value)
      .fill(0)
      .map((x, i) => i);
  }
}
