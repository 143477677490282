export const ErrorRouteNames = {
  ERROR404: '404',
  ERROR500: '500',
};

export const Error500 = {
  Sources: {
    generating: 'generating',
  },
  queryParams: {
    source: 'source',
    saveState: 'ss',
  },
};
