import { Injectable } from '@angular/core';
import { EntityCrudService } from '@data/entity-crud.service';
import { JobGeneral } from '@data/job-general/job-general.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class JobGeneralService extends EntityCrudService<JobGeneral, JobGeneral> {
  constructor(http: HttpClient) {
    super('jobs/general', http);
  }
}
