import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fn',
  pure: true,
})
export class FnPipe implements PipeTransform {
  public transform<T, R>(
    templateValue: T,
    fnReference: (target: T, ...args: any[]) => R,
    ...fnArguments: any[]
  ): R {
    return fnReference.apply(null, [templateValue, ...fnArguments]);
  }
}
