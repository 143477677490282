import {
  getEntitiesCountByPredicate,
  selectEntitiesCountByPredicate,
  selectManyByPredicate,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';
import { createStore, Store } from '@ngneat/elf';
import { Match, MatchStatus } from '@data/match/match.model';
import { Injectable } from '@angular/core';
import { MatchService } from '@data/match/match.service';
import { ActiveEntityRepository } from '@data/active-entity-repository';
import { map } from 'rxjs/operators';
import { withRequestsCache } from '@ngneat/elf-requests';

export function containsUnrated(matches: Match[]): boolean {
  return matches.some((match) => match.status <= MatchStatus.MATCH);
}

const matchStore = createStore(
  { name: 'match' },
  withEntities<Match, '_id'>({ idKey: '_id' }),
  withActiveId(),
  withRequestsCache(),
);

const sortByScoreDesc: (a: Match, b: Match) => number = (a, b) => b.score - a.score;

const sortByStatusAndScoreDesc: (a: Match, b: Match) => number = (a, b) => {
  if (a.status === b.status) {
    return sortByScoreDesc(a, b);
  }
  return a.status - b.status;
};
const sortByDateDesc: (a: Match, b: Match) => number = (a, b) =>
  (b.ratedDate ?? 0) - (a.ratedDate ?? 0);

@Injectable({ providedIn: 'root' })
export class MatchRepository extends ActiveEntityRepository<Match> {
  unratedMatchesAmount$ = this.store.pipe(
    selectEntitiesCountByPredicate((match) => match.status <= MatchStatus.MATCH),
  );

  unratedMatchesSortedByScore$ = matchStore
    .pipe(selectManyByPredicate((match) => match.status <= MatchStatus.MATCH))
    .pipe(map((it) => it?.reverse()));

  ratedMatchesSortedByScore$ = matchStore.pipe(
    selectManyByPredicate((match) => match.status > MatchStatus.MATCH),
    map((matches) => matches.sort(sortByStatusAndScoreDesc)),
  );

  ratedMatchesSortedByRatedDate$ = matchStore.pipe(
    selectManyByPredicate((match) => match.status > MatchStatus.MATCH),
    map((matches) => matches.sort(sortByDateDesc)),
  );

  protected constructor(matchService: MatchService) {
    super(matchService);
  }

  hasUnratedMatches = () =>
    this.store.query(getEntitiesCountByPredicate((match) => match.status <= MatchStatus.MATCH)) > 0;

  rate(matchId: string, status: MatchStatus) {
    return this.updateEntity(matchId, { status, ratedDate: new Date().valueOf() });
  }

  unrate(matchId: string) {
    return this.updateEntity(matchId, { status: MatchStatus.MATCH, ratedDate: undefined });
  }

  loadForSaveState(saveStateRef: string) {
    return this.loadAll({ filter: JSON.stringify({ saveStateRef }) });
  }

  protected override requireStore(): Store {
    return matchStore;
  }
}
