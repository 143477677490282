import { appConfig } from '../../../app.config';

export enum MatchStatus {
  NEW,
  MATCH,
  HIGHLIKE,
  LIKE,
  DISLIKE,
  APPLICATION,
  TRASH,
}

export const MatchStatisticNames = [
  '0_view',
  '1_match',
  '3_super_like',
  '2_like',
  '4_dislike',
  '5_application',
  '6_trash',
];

export interface Match {
  _id: string;
  score: number;
  jobRef: string;
  companyScore: number;
  jobScore: number;
  jobTypeId: number;
  status: MatchStatus;
  frame: number;
  characteristics: [
    {
      category: string;
      score: number;
    },
  ];
  confidence: number;
  bonusReasons: number;
  malusReasons: number;
  saveStateRef: string;
  creationTime: number | undefined;
  ratedDate: number | undefined;
  importedFairBoothRef?: string;
  specializationId?: string;
  markedForRoadmap?: boolean;
}

export class NextMatchGenerationStrategy {
  private static readonly NONE = null;

  private static readonly COMPANY_NO_REDUCE = new NextMatchGenerationStrategy(false, 0.0);

  private static readonly COMPANY_REDUCE = new NextMatchGenerationStrategy(false, 0.15);

  private static readonly GENERAL_NO_REDUCE = new NextMatchGenerationStrategy(true, 0.0);

  private static readonly GENERAL_REDUCE = new NextMatchGenerationStrategy(true, 0.15);

  private static currentStrategy: NextMatchGenerationStrategy | null = null;

  private static COMPANY_STRATEGIES = [
    NextMatchGenerationStrategy.COMPANY_NO_REDUCE,
    NextMatchGenerationStrategy.COMPANY_REDUCE,
  ];

  private static GENERAL_STRATEGIES = [
    NextMatchGenerationStrategy.GENERAL_NO_REDUCE,
    NextMatchGenerationStrategy.GENERAL_REDUCE,
  ];

  private static STRATEGIES: NextMatchGenerationStrategy[] = [];

  private constructor(public readonly general: boolean, public readonly reduceBy: number) {}

  public static reset() {
    NextMatchGenerationStrategy.STRATEGIES = [];
    if (appConfig.modules.matching.allowCompanyMatches) {
      NextMatchGenerationStrategy.STRATEGIES.push(
        ...NextMatchGenerationStrategy.COMPANY_STRATEGIES,
      );
    }
    if (appConfig.modules.matching.allowGeneralMatches) {
      NextMatchGenerationStrategy.STRATEGIES.push(...NextMatchGenerationStrategy.GENERAL_STRATEGIES);
    }
    NextMatchGenerationStrategy.currentStrategy = null;
  }

  public static next() {
    if (NextMatchGenerationStrategy.currentStrategy === null) {
      NextMatchGenerationStrategy.currentStrategy = NextMatchGenerationStrategy.STRATEGIES[0];
      return NextMatchGenerationStrategy.currentStrategy;
    }
    const index = NextMatchGenerationStrategy.STRATEGIES.indexOf(
      NextMatchGenerationStrategy.currentStrategy,
    );
    if (index === -1 || index === NextMatchGenerationStrategy.STRATEGIES.length - 1) {
      return NextMatchGenerationStrategy.NONE;
    }

    NextMatchGenerationStrategy.currentStrategy = NextMatchGenerationStrategy.STRATEGIES[index + 1];
    return NextMatchGenerationStrategy.currentStrategy;
  }
}
