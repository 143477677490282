import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { UserRepository } from '@data/user/user.store';
import { SaveStateRepository } from '@data/save-state/save-state.store';
import { AppRouteNames } from '../../app.routing.names';
import { ErrorRouteNames } from '../../modules/error/error.routing.names';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private userRepo: UserRepository,
    private saveStateRepo: SaveStateRepository,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    if (this.userRepo.user) return true;
    let userId = route.queryParamMap.get('userId');
    return this.userRepo.loginUser(userId).pipe(
      map((data) => {
        if (data) return true;
        else {
          this.router.navigate([AppRouteNames.ERROR, ErrorRouteNames.ERROR404], {
            queryParamsHandling: 'merge',
          });
          return false;
        }
      }),
    );
  }
}
