import { Injectable } from '@angular/core';
import { DocType, FileDataDTO, Realm } from '@data/file/file.model';
import { Observable } from 'rxjs';
import { IonosService } from '@core/services/s3/ionos.service';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private fileService: IonosService) {}

  download(realm: Realm, type: DocType, id: string, name: string): Observable<Blob> {
    return this.fileService.download(realm, type, id, name);
  }

  meta(realm: Realm, type: DocType, id: string): Observable<FileDataDTO[]> {
    return this.fileService.meta(realm, type, id);
  }
}
