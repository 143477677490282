<div
  @fadeIn
  class="full-screen overlay"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="56px"
>
  <recrewt-story-guide
    (dismiss)="dismiss.emit()"
    [buttonTemplate]="buttonTemplate"
    [desc]="message"
    [dismissable]="dismissable"
    [guidePosition]="'bottom-center'"
    [hoverHide]="false"
    [imageSrc]="imageSrc"
  ></recrewt-story-guide>
</div>
