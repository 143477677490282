import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Location } from '@data/matching-session/matching-session.model';

function stripCountry(address: string) {
  if (address.endsWith('Deutschland')) {
    return address.substring(0, address.lastIndexOf(','));
  }
  return address;
}

function createLocationFromSearchResult(address: Feature): Location {
  return {
    address: stripCountry(address.place_name),
    region: address.context[3]?.text ?? 'Deutschland',
    location: {
      type: 'Point',
      coordinates: [+address.center[0], +address.center[1]],
    },
  };
}

export interface MapboxOutput {
  attribution: string;
  features: Feature[];
  query: [];
}

export interface Feature {
  place_name: string;
  context: any[];
  center: string[];
}

@Injectable({
  providedIn: 'root',
})
export class GeocoderService {
  private geocodeUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';

  constructor(private http: HttpClient) {}

  public searchAddress(query: string, type: string = 'address'): Observable<Location[]> {
    const url: string =
      this.geocodeUrl +
      query +
      `.json?country=DE&language=DE&types=${type},address&limit=7&access_token=` +
      environment.mapbox.apiKey;
    return this.http
      .get<MapboxOutput>(url)
      .pipe(
        map((res) => {
          return res.features;
        }),
      )
      .pipe(map((value) => value.map((address) => createLocationFromSearchResult(address))));
  }
}
