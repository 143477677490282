export class ArrayUtil {
  public static toMap(arr: any[], keyFn: (item: any) => any): Map<any, any> {
    const map = new Map();
    arr.forEach((it) => map.set(keyFn(it), it));
    return map;
  }
}

const booleanToNumber = (b: boolean): number => {
  return b ? 1 : 0;
};

export function compare(
  a?: number | string | boolean,
  b?: number | string | boolean,
  isAsc?: boolean,
): number {
  if (a == null) {
    return isAsc ? -1 : 1;
  }
  if (b == null) {
    return isAsc ? 1 : -1;
  }

  if (typeof a === 'boolean') a = booleanToNumber(a);
  if (typeof b === 'boolean') b = booleanToNumber(b);

  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
