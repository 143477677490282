<section [ngStyle]="carouselWrapperStyle" class="carousel-wrapper">
  <ul #carousel class="carousel-inner">
    <li *ngFor="let item of items" class="carousel-item">
      <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
    </li>
  </ul>
  <div
    *ngIf="showControls"
    class="width-match-parent"
    fxLayoutAlign="center start"
    fxLayoutGap="16px"
  >
    <button (click)="prev()" color="primary" mat-icon-button>
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button (click)="next()" color="primary" mat-icon-button>
      <mat-icon> chevron_right </mat-icon>
    </button>
  </div>
</section>
