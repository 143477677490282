import { Injectable } from '@angular/core';
import { EntityCrudService } from '@data/entity-crud.service';
import { HttpClient } from '@angular/common/http';
import { Match } from '@data/match/match.model';

@Injectable({
  providedIn: 'root',
})
export class MatchService extends EntityCrudService<Match, Match> {
  constructor(http: HttpClient) {
    super('matches', http);
  }
}
