import { Component, Input } from '@angular/core';
import { appConfig } from '../../../../app.config';

@Component({
  selector: 'recrewt-bordered-icon-button',
  templateUrl: './bordered-icon-button.component.html',
  styleUrls: ['./bordered-icon-button.component.scss'],
})
export class BorderedIconButtonComponent {
  @Input() icon?: string;

  @Input() color = appConfig.theme.accent;
}
