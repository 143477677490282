<div
  (click)="dismissable ? hideUntilLater() : null"
  *ngIf="text"
  [class.hoverBubble]="hoverHide"
  class="bubble bubble-bottom-left mat-elevation-z3 width-40"
  ngClass.lt-lg="width-50"
  ngClass.lt-sm="width-mobile"
>
  <mat-icon (click)="hideUntilLater()" *ngIf="dismissable" class="close-icon" color="primary"
    >close</mat-icon
  >
  <div *ngIf="showImage" class="m-v-2" fxLayoutAlign="center">
    <img @fadeIn [src]="imageSrc" alt="" class="m-b-2 mat-elevation-z3 contain-image" />
  </div>
  <p class="no-pad p-r-3 show-breaks">{{ autoTypedText }}</p>
  <ng-template *ngIf="buttonTemplate" [ngTemplateOutlet]="buttonTemplate"></ng-template>
</div>
