import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { UserRepository } from '@data/user/user.store';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppRouteNames } from '../../app.routing.names';

@Injectable()
export class UseridInterceptor implements HttpInterceptor {
  constructor(private userRepo: UserRepository, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const account = this.userRepo.user;
    const isLoggedIn = account?.id;
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${account!.id}` },
      });
    }

    return next.handle(request).pipe(catchError((error) => this.handleUnauthorizedError(error)));
  }

  handleUnauthorizedError(error: any): Observable<any> {
    if (!(error instanceof HttpErrorResponse)) {
      return throwError(() => error);
    }
    if (error.status === HttpStatusCode.Unauthorized) {
      this.router.navigate([AppRouteNames.HOME]);
      return of(error);
    }

    return throwError(() => error);
  }
}
