import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractMatchItemComponent } from '@shared/components/match-item/abstract-match-item.component';
import { appConfig } from '../../../../app.config';

@Component({
  selector: 'recrewt-match-item',
  templateUrl: './match-item.component.html',
  styleUrls: ['./match-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchItemComponent extends AbstractMatchItemComponent implements OnInit {
  @Input() tag?: string;

  @Input() allowMarking = false;

  @Input() linkActive = true;

  @Output() markChange = new EventEmitter();

  desc: string | null = null;

  externalActions = appConfig.modules.matching.externalJobActions;

  override ngOnInit() {
    super.ngOnInit();
    this.desc = this.company?.identifiableName ?? null;
  }
}
