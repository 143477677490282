import { Component, ContentChildren, QueryList } from '@angular/core';
import { SwipeableDirective } from './swipeable.directive';

@Component({
  selector: 'recrewt-swipe-card-wrapper',
  templateUrl: './swipe-card-wrapper.component.html',
  styleUrls: ['./swipe-card-wrapper.component.scss'],
})
export class SwipeCardWrapperComponent {
  @ContentChildren(SwipeableDirective) cards!: QueryList<SwipeableDirective>;

  public like(data?: any): void {
    if (this.cards.first) {
      this.cards.first.simulateSwipe(true, data);
    }
  }

  public dislike(data?: any): void {
    if (this.cards.first) {
      this.cards.first.simulateSwipe(false, data);
    }
  }
}
