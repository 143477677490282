import { createState, Store } from '@ngneat/elf';
import { selectEntityByPredicate, withEntities } from '@ngneat/elf-entities';
import { Injectable } from '@angular/core';
import { EntityRepository } from '@data/entity-repository';
import { FairBooth } from '@data/fair-booth/fair-booth.model';
import { FairBoothService } from '@data/fair-booth/fair-booth.service';
import { withRequestsCache } from '@ngneat/elf-requests';
import { EMPTY, Observable } from 'rxjs';

const { state, config } = createState(withEntities<FairBooth>(), withRequestsCache());

const fairBoothStore = new Store({ name: 'fair-booth', state, config });

@Injectable({ providedIn: 'root' })
export class FairBoothRepository extends EntityRepository<FairBooth> {
  protected constructor(fairBoothService: FairBoothService) {
    super(fairBoothService);
  }

  getByCompanyId(companyId?: string): Observable<FairBooth | undefined> {
    if (!companyId) return EMPTY;
    return this.store.pipe(selectEntityByPredicate((it) => it.company === companyId));
  }

  protected override requireStore(): Store {
    return fairBoothStore;
  }
}
