import { Injectable, Type, ViewContainerRef } from '@angular/core';

export type Attributes = { [key: string]: string };

@Injectable({
  providedIn: 'root',
})
export class DynamicComponentService {
  loadComponent(vcr: ViewContainerRef, type: Type<any>, data: any, attributes?: Attributes): void {
    const componentRef = vcr.createComponent(type);
    componentRef.instance.data = data;
    if (attributes) {
      Object.entries(attributes).forEach(([key, value]) => {
        componentRef.location.nativeElement.setAttribute(key, value);
      });
    }
  }
}
