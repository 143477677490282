import { Injectable } from '@angular/core';
import { EntityCrudService } from '@data/entity-crud.service';
import { HttpClient } from '@angular/common/http';
import { SaveState } from '@data/save-state/save-state.model';

@Injectable({
  providedIn: 'root',
})
export class SaveStateService extends EntityCrudService<SaveState, SaveState> {
  constructor(http: HttpClient) {
    super('save-states', http);
  }
}
