import { createState, Store } from '@ngneat/elf';
import { withEntities } from '@ngneat/elf-entities';
import { asFlag, getBranchIcon, JobGeneral } from '@data/job-general/job-general.model';
import { Injectable } from '@angular/core';
import { EntityRepository } from '@data/entity-repository';
import { Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { JobGeneralService } from '@data/job-general/job-general.service';
import { trackRequestResult, withRequestsCache } from '@ngneat/elf-requests';
import { TranslateService } from '@ngx-translate/core';
import { SupportedLanguages } from '@core/constants/system/lang';

const { state, config } = createState(withEntities<JobGeneral>(), withRequestsCache());

const jobGeneralStore = new Store({ name: 'job-general', state, config });

@Injectable({ providedIn: 'root' })
export class JobGeneralRepository extends EntityRepository<JobGeneral> {
  protected constructor(jobGeneralService: JobGeneralService, private translate: TranslateService) {
    super(jobGeneralService);
  }

  override loadAll(): Observable<JobGeneral[] | undefined> {
    const lang = this.translate.currentLang as SupportedLanguages;
    return this.service.get().pipe(
      map((entities) => entities?.map((entity) => asFlag(entity, lang))),
      tap((entities) => this.set(entities ?? [])),
      trackRequestResult([this.store.name]),
      this.skipWhileCached(this.store.name, { returnSource: this.all$ }),
    );
  }

  selectJobName = (id?: string | number) => {
    const lang = this.translate.currentLang as SupportedLanguages;
    return this.all$.pipe(
      map((all) => {
        const job = all.find((job) => job.id === id?.toString());
        return job?.name[lang] ?? job?.name.de ?? '';
      }),
    );
  };

  selectJobIcon = (jobTypeId?: number) => {
    return this.all$.pipe(
      map((all) => {
        let jobGeneral = all.find((job) => job.flag === jobTypeId);
        if (!jobGeneral) {
          return 'star';
        }
        return getBranchIcon(jobGeneral);
      }),
    );
  };

  protected override requireStore(): Store {
    return jobGeneralStore;
  }
}
