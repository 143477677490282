import { createState, Store } from '@ngneat/elf';
import { withEntities } from '@ngneat/elf-entities';
import { Injectable } from '@angular/core';
import { EntityRepository } from '@data/entity-repository';
import { JobService } from '@data/job/job.service';
import { Job } from '@data/job/job.model';
import { withRequestsCache } from '@ngneat/elf-requests';
import { MatchStatus } from '@data/match/match.model';

const { state, config } = createState(withEntities<Job>(), withRequestsCache());

const jobStore = new Store({ name: 'job', state, config });

@Injectable({ providedIn: 'root' })
export class JobRepository extends EntityRepository<Job> {
  protected constructor(private jobService: JobService) {
    super(jobService);
  }

  incrementStat(jobId: string, status: MatchStatus) {
    return this.jobService.incrementStat(jobId, status);
  }

  decrementStat(jobId: string, status: MatchStatus) {
    return this.jobService.decrementStat(jobId, status);
  }

  protected override requireStore(): Store {
    return jobStore;
  }
}
