import { createState, Store } from '@ngneat/elf';
import { withEntities } from '@ngneat/elf-entities';
import { Injectable } from '@angular/core';
import { EntityRepository } from '@data/entity-repository';
import { ImportedCompany } from '@data/imported-company/imported-company.model';
import { ImportedCompanyService } from '@data/imported-company/imported-company.service';
import { withRequestsCache } from '@ngneat/elf-requests';

const { state, config } = createState(withEntities<ImportedCompany>(), withRequestsCache());

const importedCompanyStore = new Store({ name: 'importedCompany', state, config });

@Injectable({ providedIn: 'root' })
export class ImportedCompanyRepository extends EntityRepository<ImportedCompany> {
  protected constructor(importedCompanyService: ImportedCompanyService) {
    super(importedCompanyService);
  }

  protected override requireStore(): Store {
    return importedCompanyStore;
  }
}
