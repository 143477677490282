import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'url',
})
export class UrlPipe implements PipeTransform {
  transform(blob: Blob | null | undefined): string | null {
    if (!blob?.size) return null;
    return window.URL.createObjectURL(blob);
  }
}
