<div [formGroup]="form" hidden>
  <input [formControlName]="controlName" [required]="require" />
</div>

<mat-form-field
  [class.no-hint]="!error"
  [floatLabel]="disableFloatLabel ? 'never' : 'auto'"
  appearance="outline"
  class="on-surface"
  fxFlex="grow"
>
  <input
    #input
    (input)="searchControl.setValue(input.value)"
    [matAutocomplete]="auto"
    [ngModel]="initValue"
    [placeholder]="disableFloatLabel ? ('LOCATION_SELECT.placeholder' | translate) : ''"
    autocomplete="new-address"
    matInput
    name="query"
  />
  <mat-icon matSuffix>location_on</mat-icon>
  <mat-error *ngIf="error">{{ error | translate }}</mat-error>
  <mat-label *ngIf="!disableFloatLabel">{{ 'LOCATION_SELECT.placeholder' | translate }}</mat-label>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="setFormFieldValue($event.option.value)"
    [displayWith]="displayValue"
  >
    <mat-option *ngFor="let value of results$ | async" [value]="value">
      <mat-icon class="on-surface-medium">place</mat-icon>
      <span>{{ street(value) }}</span> |
      <small class="on-surface-medium">{{ city(value) }}</small>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
