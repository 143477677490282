import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Animations } from '@styles/animation/animations';

@Component({
  selector: 'recrewt-mascot-dialog',
  templateUrl: './mascot-dialog.component.html',
  styleUrls: ['./mascot-dialog.component.scss'],
  animations: [Animations.fadeIn],
})
export class MascotDialogComponent {
  @Input() message: string = '';

  @Input() imageSrc: string | null = null;

  @Input() dismissable: boolean = true;

  @Input() buttonTemplate?: TemplateRef<any>;

  @Output() dismiss: EventEmitter<void> = new EventEmitter();
}
