import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

type MaybeReturns<T> = T | undefined;

export interface IHTTPParamsObject {
  [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
}

export abstract class EntityCrudService<I, O> {
  protected readonly URL: string;

  protected readonly PATH: string | undefined;

  protected constructor(
    private route: string,
    protected http: HttpClient,
    private path: string | undefined = undefined,
  ) {
    this.URL = `${environment.apiUrl}/${this.route}`;
    this.PATH = path;
  }

  getById(id: string, params?: IHTTPParamsObject): Observable<O | undefined> {
    const httpParams = new HttpParams({ fromObject: params });
    return this.http.get<O>(`${this.URL}/${id}`, { params: httpParams });
  }

  get(params?: IHTTPParamsObject): Observable<O[] | undefined> {
    const httpParams = new HttpParams({ fromObject: params });
    return this.http.get<O[]>(`${this.URL}`, { params: httpParams });
  }

  update(id: string, changes: Partial<I>) {
    return this.http.put(`${this.URL}/${id}`, changes);
  }

  create(data: I) {
    return this.http.post<MaybeReturns<O>>(`${this.URL}`, data);
  }
}

export interface IRequestProps<I> {
  input: I;
}

export interface IBatchRequestProps<I> {
  input: I[];
}

export interface ICreateWithForeignIdProps<I> extends IRequestProps<I> {
  foreignId: string;
}

export interface IBatchCreateWithForeignIdProps<I> extends IBatchRequestProps<I> {
  foreignId: string;
}

export interface IUpdateWithForeignIdProps<I> extends IRequestProps<I> {
  foreignId: string;
  targetId: string;
}
