<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<form (submit)="submit()" [formGroup]="form" mat-dialog-content>
  <p class="info-box">{{ data.message | translate }}</p>
  <mat-form-field appearance="outline" class="block">
    <input formControlName="name" matInput required />
    <mat-error>{{ data.error | translate }}</mat-error>
    <mat-label>{{ data.label | translate }}</mat-label>
  </mat-form-field>
</form>
<div class="p-b-2" fxLayoutAlign="end start" mat-dialog-actions>
  <button (click)="onNoClick()" mat-button>{{ 'COMMON.cancel' | translate }}</button>
  <button (click)="submit()" color="accent" mat-flat-button type="submit">
    {{ data.action ?? 'COMMON.confirm' | translate }}
  </button>
</div>
