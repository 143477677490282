<canvas
  #confettiCanvas
  class="height-match-parent width-match-parent"
  style="position: absolute; top: 0; left: 0"
></canvas>
<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div mat-dialog-content>
  <div>
    <p style="white-space: pre-line">{{ data.message | translate : { arg: data.arg } }}</p>
  </div>
</div>
<div class="p-b-2" fxLayoutAlign="end start" mat-dialog-actions>
  <button (click)="noActionClicked()" *ngIf="data.cancelText" mat-raised-button>
    {{ data.cancelText | translate }}
  </button>
  <button (click)="actionClicked(true)" color="primary" mat-raised-button>
    {{ data.confirmText | translate }}
  </button>
</div>
