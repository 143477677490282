import {
  ComponentRef,
  Directive,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ThemePalette } from '@angular/material/core';
import { MatButton } from '@angular/material/button';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: `button[mat-button][loading], button[mat-raised-button][loading], button[mat-icon-button][loading],
             button[mat-fab][loading], button[mat-mini-fab][loading], button[mat-stroked-button][loading],
             button[mat-flat-button][loading]`,
})
export class MatButtonLoadingDirective implements OnInit, OnChanges {
  @Input()
  loading = false;

  @Input()
  disabled = false;

  @Input()
  color: ThemePalette;

  private spinner: ComponentRef<MatProgressSpinner> | null = null;

  constructor(
    private matButton: MatButton,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.matButton.disabled = this.matButton.disabled || this.loading;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['loading']) {
      return;
    }

    if (changes['loading'].currentValue) {
      this.matButton._elementRef.nativeElement.classList.add('mat-loading');
      this.matButton.disabled = true;
      this.createSpinner();
    } else if (!changes['loading'].firstChange) {
      this.matButton._elementRef.nativeElement.classList.remove('mat-loading');
      this.matButton.disabled = this.matButton.disabled || this.disabled;
      this.destroySpinner();
    }
  }

  private createSpinner(): void {
    if (!this.spinner) {
      this.spinner = this.viewContainerRef.createComponent(MatProgressSpinner);
      this.spinner.instance.color = this.color;
      this.spinner.instance.diameter = 20;
      this.spinner.instance.mode = 'indeterminate';
      this.renderer.appendChild(
        this.matButton._elementRef.nativeElement,
        this.spinner.instance._elementRef.nativeElement,
      );
      //center in button
      this.renderer.setStyle(
        this.spinner.instance._elementRef.nativeElement,
        'position',
        'absolute',
      );
      this.renderer.setStyle(this.spinner.instance._elementRef.nativeElement, 'top', '50%');
      this.renderer.setStyle(this.spinner.instance._elementRef.nativeElement, 'left', '50%');
      this.renderer.setStyle(
        this.spinner.instance._elementRef.nativeElement,
        'transform',
        'translate(-50%, -50%)',
      );
    }
  }

  private destroySpinner(): void {
    if (this.spinner) {
      this.spinner.destroy();
      this.spinner = null;
    }
  }
}
