import { Directive, EventEmitter, Output, TemplateRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[carouselItem]',
})
export class CarouselItemDirective {
  @Output() lazyLoad = new EventEmitter<void>();

  constructor(public tpl: TemplateRef<any>) {}
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '.carousel-item',
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class CarouselItemElement {}
