import { Component, Input, OnInit, Type, ViewChild } from '@angular/core';
import {
  Attributes,
  DynamicComponentService,
} from '@core/services/template/dynamic-component.service';
import { TemplateHostDirective } from '@shared/directives/template-host.directive';
import { IDynamicComponent } from '@core/services/template/dynamic.component';

@Component({
  selector: 'recrewt-section-content',
  templateUrl: './section-content.component.html',
  styleUrls: ['./section-content.component.scss'],
})
export class SectionContentComponent implements OnInit {
  @Input() data: any = null;

  @Input() attributes?: Attributes;

  @Input() sectionType: Type<IDynamicComponent> | null = null;

  @ViewChild(TemplateHostDirective, { static: true })
  tplHost: TemplateHostDirective | undefined;

  constructor(private tplLoader: DynamicComponentService) {}

  ngOnInit(): void {
    if (!this.tplHost || !this.sectionType) {
      return;
    }
    this.tplLoader.loadComponent(
      this.tplHost.viewContainerRef,
      this.sectionType,
      this.data,
      this.attributes,
    );
  }
}
