import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { JobGeneralRepository } from '@data/job-general/job-general.store';

@Injectable({
  providedIn: 'root',
})
export class JobGeneralResolver implements Resolve<boolean> {
  constructor(private jobGeneralRepo: JobGeneralRepository) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.jobGeneralRepo.loadAll().subscribe();
    return this.jobGeneralRepo.isSuccess$();
  }
}
