<div class="height-match-parent">
  <mat-toolbar color="primary">
    <mat-toolbar-row
      class="first-toolbar-row no-select mat-elevation-z2"
      fxLayoutAlign="space-between center"
    >
      <div fxLayoutAlign="center center">
        <a *ngIf="logoWithName" routerLink="/">
          <img [src]="logoWithName" alt="Recrewt Logo" class="nav-home-logo" />
        </a>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="toolbar-content">
    <div class="nav-content height-match-parent">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
