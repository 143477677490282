import { Pipe, PipeTransform } from '@angular/core';
import { JobGeneral } from '@data/job-general/job-general.model';
import { get } from 'lodash';
import { JobGeneralRepository } from '@data/job-general/job-general.store';

@Pipe({
  name: 'jobTypeToValue',
})
export class JobTypeToValuePipe implements PipeTransform {
  constructor(private jobs: JobGeneralRepository) {}

  transform(value: string | undefined, field: string, fallbackField?: keyof JobGeneral): any {
    const job = this.jobs.getById(value);
    if (!job) {
      return undefined;
    }
    const data = get(job, field);
    if (!data && fallbackField) {
      return get(job, fallbackField);
    }
    return data;
  }
}
