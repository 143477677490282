import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { first, Observable, of } from 'rxjs';
import { GeocoderService } from '@core/services/geocoder/geocoder.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Location } from '@data/matching-session/matching-session.model';

@UntilDestroy()
@Component({
  selector: 'recrewt-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss'],
})
export class LocationSelectorComponent implements OnInit {
  form!: FormGroup;

  @Input() controlName!: string;

  @Input() error?: string = 'LOCATION_SELECT.error';

  @Input() require = false;

  @Input() disableFloatLabel = false;

  @Input() type: 'address' | 'place' = 'address';

  searchControl = new FormControl();

  initValue?: any;

  results$: Observable<Location[]> = of([]);

  constructor(
    private rootFormGroup: FormGroupDirective,
    private locationService: GeocoderService,
    private cdr: ChangeDetectorRef,
  ) {}

  displayValue = (value: Location) => value?.address ?? '';

  street = (value: Location) => value?.address?.split(',')[0]?.trim() ?? '';

  city = (value: Location) => value?.address?.split(',')[1]?.trim() ?? '';

  ngOnInit(): void {
    this.form = this.rootFormGroup.control as FormGroup;

    this.initFromQuery();
    this.observeValueChange();
    this.observeSearchValueChange();
  }

  setFormFieldValue(value: Location) {
    this.form.get(this.controlName)?.setValue(value);
    this.cdr.detectChanges();
  }

  private observeValueChange() {
    this.form
      .get(this.controlName)
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.results$ = of([value]);
        this.results$.pipe(first()).subscribe((res) => {
          this.initValue = res?.[0];
          this.cdr.detectChanges();
        });
      });
  }

  private initFromQuery() {
    const initValue = this.form.get(this.controlName)?.value;
    if (typeof initValue === 'string') {
      this.results$ = this.locationService.searchAddress(
        initValue.trim()?.toLowerCase(),
        this.type,
      );

      this.results$.pipe(first()).subscribe((res) => {
        this.initValue = res?.[0];
        this.setFormFieldValue(res?.[0]);
      });
    }
  }

  private observeSearchValueChange() {
    this.searchControl.valueChanges
      .pipe(debounceTime(500), untilDestroyed(this))
      .subscribe((term: string) => {
        const query = term?.trim() ?? '';
        if (query.length < 3) {
          return;
        }

        this.results$ = this.locationService.searchAddress(term?.trim()?.toLowerCase(), this.type);
        this.cdr.detectChanges();
      });
  }
}
