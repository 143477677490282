<mat-form-field class="remove-form-border" style="width: 56px">
  <mat-select (valueChange)="changeLanguage($event)" [value]="language.value">
    <mat-select-trigger>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="padding: 0 16px">
        <img [src]="language.img" alt="" class="flag" />
      </div>
    </mat-select-trigger>
    <mat-option *ngFor="let opt of languages" [value]="opt.value">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <img [src]="opt.img" class="flag" />
        <span>{{ opt.label }}</span>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
