export const environment = {
  production: false,
  apiUrl: 'https://test.api.recrewt.de/api/web',
  rollbar: {
    key: '50f042ead7eb469aa751e6af185f4966',
  },
  rottalInn: {
    companyBaseUrl: 'https://www.berufswahl-rottal-inn.de/ausbildung/unternehmen/',
    schoolBaseUrl: 'https://www.berufswahl-rottal-inn.de/ausbildung/berufsfachschulen/',
    queryKey: 'beruf',
  },
  mapbox: {
    apiKey:
      'pk.eyJ1IjoidmF2ZS1kZXYiLCJhIjoiY2t1OWczcGJqMDZrdTJwcXY4NXF5MnYxNiJ9.Efn0pOFJPgDNqn4l9XesIw',
  },
};
