import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[recrewtSelectable]',
})
export class SelectableDirective implements OnInit {
  @Input() public value: any;

  @Input() public applyToChildWithId?: string;

  @Output() public selected = new EventEmitter<any>();

  @Output() public deselected = new EventEmitter<any>();

  private isSelected = false;

  private element!: HTMLElement;

  constructor(protected el: ElementRef, public renderer: Renderer2) {}

  ngOnInit() {
    if (this.applyToChildWithId) {
      this.element = this.el.nativeElement.querySelector(`.${this.applyToChildWithId}`);
    } else {
      this.element = this.el.nativeElement;
    }
    this.renderer.addClass(this.element, 'mat-elevation-z3');
    this.renderer.setStyle(this.element, 'cursor', 'pointer');
    this.renderer.setStyle(this.element, 'transition', 'background-color 0.2s ease-in-out');
  }

  @HostListener('click', ['$event'])
  onClick() {
    if (!this.isSelected) {
      this.isSelected = true;
      this.renderer.setStyle(this.element, 'background-color', 'lightgray');
      this.renderer.removeClass(this.element, 'mat-elevation-z3');
      this.renderer.addClass(this.element, 'mat-elevation-z1');
      this.selected.emit(this.value);
    } else {
      this.isSelected = false;
      this.renderer.setStyle(this.element, 'background-color', null);
      this.renderer.addClass(this.element, 'mat-elevation-z3');
      this.renderer.addClass(this.element, 'surface');
      this.renderer.removeClass(this.element, 'mat-elevation-z1');
      this.deselected.emit(this.value);
    }
  }
}
