import { Component } from '@angular/core';
import { appConfig } from '../../../app.config';

@Component({
  selector: 'recrewt-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  readonly currentYear = new Date().getFullYear();

  readonly footerSrc = appConfig.modules.home.footerImageSrc;

  readonly fundedBySrc = appConfig.modules.home.fundedByImageSrc;
}
