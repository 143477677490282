import { Injectable } from '@angular/core';
import { EntityCrudService } from '@data/entity-crud.service';
import { HttpClient } from '@angular/common/http';
import { Job } from '@data/job/job.model';
import { MatchStatisticNames, MatchStatus } from '@data/match/match.model';
import { appConfig } from '../../../app.config';
import { EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JobService extends EntityCrudService<Job, Job> {
  constructor(http: HttpClient) {
    super('jobs/registered', http);
  }

  incrementStat(jobId: string, status: MatchStatus) {
    if (!appConfig.modules.matching.trackStats) {
      return EMPTY;
    }
    const date = new Date().toISOString().slice(0, 10);
    const statistic = MatchStatisticNames[status];
    return this.http.put(`${this.URL}/stat/${jobId}/${date}/${statistic}`, null);
  }

  decrementStat(jobId: string, status: MatchStatus) {
    if (!appConfig.modules.matching.trackStats) {
      return EMPTY;
    }
    const date = new Date().toISOString().slice(0, 10);
    const statistic = MatchStatisticNames[status];
    return this.http.put(`${this.URL}/dec_stat/${jobId}/${date}/${statistic}`, null);
  }
}
