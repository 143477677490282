import { Injectable } from '@angular/core';
import { EntityCrudService } from '@data/entity-crud.service';
import { HttpClient } from '@angular/common/http';
import { ImportedCompany } from '@data/imported-company/imported-company.model';

@Injectable({
  providedIn: 'root',
})
export class ImportedCompanyService extends EntityCrudService<ImportedCompany, ImportedCompany> {
  constructor(http: HttpClient) {
    super('companies/imported', http);
  }
}
