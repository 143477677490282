const mime = require('mime');

export interface FileData {
  id: string;
  name: string;
  type: string;
  size: number;
  forId: string;
  docType: DocType;
  blob?: Blob;
  fqn: string;
}

export interface FileDataDTO {
  name: string;
  size: number;
}

export type DocType =
  | 'company/logo'
  | 'company/banner'
  | 'company/media'
  | 'company/privacy'
  | 'company/document'
  | 'job/file'
  | 'job/banner'
  | 'job/media'
  | 'internal/data';

export type Realm = 'companies' | 'jobs' | 'external-companies' | 'internal';

export function createFromFile(file: File, forId: string, docType: DocType): FileData {
  return {
    name: file.name,
    size: +file.size,
    type: file.type,
    forId,
    docType,
    id: file.name + file.size + forId,
    blob: file,
    fqn: `${docType}/${forId}/${file.name}`,
  } as FileData;
}

export function createFromBlob(
  blob: Blob,
  forId: string,
  name: string,
  docType: DocType,
): FileData {
  const mimetype = mime.getType(name) ?? blob.type;
  return {
    name,
    size: +blob.size,
    type: mimetype,
    forId,
    docType,
    id: name + blob.size + forId,
    blob,
    fqn: `${docType}/${forId}/${name}`,
  } as FileData;
}

export function createFromDTO(file: FileDataDTO, forId: string, docType: DocType): FileData {
  const mimetype = mime.getType(file.name);
  return {
    name: file.name,
    size: file.size,
    type: mimetype,
    forId,
    docType,
    id: file.name + file.size + forId,
    fqn: `${docType}/${forId}/${file.name}`,
  } as FileData;
}
