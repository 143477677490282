import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppRouteNames } from '../../app.routing.names';
import { HomeRouteNames } from '../../modules/home/home.routing.names';

@Injectable({
  providedIn: 'root',
})
export class PseudoAuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate() {
    const loggedIn = localStorage.getItem('pseudoLogin') === 'true';
    if (!loggedIn) {
      return this.router.navigate([AppRouteNames.HOME, HomeRouteNames.PSEUDO_LOGIN]);
    }
    return loggedIn;
  }
}
