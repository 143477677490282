import { Injectable } from '@angular/core';
import { EntityCrudService } from '@data/entity-crud.service';
import { HttpClient } from '@angular/common/http';
import { Company } from '@data/company/company.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends EntityCrudService<Company, Company> {
  constructor(http: HttpClient) {
    super('companies/registered', http);
  }
}
