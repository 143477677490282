import { Injectable } from '@angular/core';
import { EntityCrudService } from '@data/entity-crud.service';
import { HttpClient } from '@angular/common/http';
import { FairBooth } from '@data/fair-booth/fair-booth.model';

@Injectable({
  providedIn: 'root',
})
export class FairBoothService extends EntityCrudService<FairBooth, FairBooth> {
  constructor(http: HttpClient) {
    super('fair-booths/registered', http);
  }
}
