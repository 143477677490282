import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  HostListener,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  CarouselItemDirective,
  CarouselItemElement,
} from '@shared/components/carousel/carousel.directive';
import {
  animate,
  AnimationBuilder,
  AnimationFactory,
  AnimationPlayer,
  style,
} from '@angular/animations';

@Component({
  selector: 'recrewt-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements AfterViewInit {
  @Input() timing = '250ms ease-in';

  @Input() showControls = true;

  carouselWrapperStyle = {};

  @ContentChildren(CarouselItemDirective) items?: QueryList<CarouselItemDirective>;

  private player?: AnimationPlayer;

  private itemWidth = 1;

  private currentSlide = 0;

  @ViewChild('carousel') private carousel?: ElementRef;

  @ViewChildren(CarouselItemElement, { read: ElementRef })
  private itemsElements?: QueryList<ElementRef>;

  constructor(private builder: AnimationBuilder, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.updateWidth();
    this.items?.first.lazyLoad.emit();
    this.cdr.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateWidth();
    this.cdr.detectChanges();
  }

  next() {
    if (this.currentSlide + 1 === this.items?.length) return;

    const length = this.items?.length ?? 1;
    this.currentSlide = (this.currentSlide + 1) % length;

    const offset = this.currentSlide * this.itemWidth;
    const myAnimation: AnimationFactory = this.builder.build([
      animate(this.timing, style({ transform: `translateX(-${offset}px)` })),
    ]);

    this.player = myAnimation.create(this.carousel?.nativeElement);
    this.player.play();
    this.items?.get(this.currentSlide)?.lazyLoad.emit();
  }

  prev() {
    if (this.currentSlide === 0) return;

    const length = this.items?.length ?? 1;
    this.currentSlide = (this.currentSlide - 1 + length) % length;

    const offset = this.currentSlide * this.itemWidth;
    const myAnimation: AnimationFactory = this.builder.build([
      animate(this.timing, style({ transform: `translateX(-${offset}px)` })),
    ]);

    this.player = myAnimation.create(this.carousel?.nativeElement);
    this.player.play();
    this.items?.get(this.currentSlide)?.lazyLoad.emit();
  }

  private updateWidth() {
    this.itemWidth = this.itemsElements?.first?.nativeElement.getBoundingClientRect().width;
    this.carouselWrapperStyle = {
      width: `${this.itemWidth}px`,
    };
  }
}
