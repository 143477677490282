import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';

export interface ConfirmDialogData {
  title: string;
  message: string;
  confirmText: string;
  altConfirmText?: string;
  cancelText?: string;
  confirmColor?: ThemePalette;
}

@Component({
  selector: 'recrewt-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
  ) {
    dialogRef.disableClose = true;
  }

  noActionClicked(): void {
    this.dialogRef.close(null);
  }
}
