import { ContextMode } from '@core/enums/context-mode';
import { AppConfig } from 'app-config';

export const appConfig: AppConfig = {
  app: {
    name: 'rottal-inn-fair',
    title: 'Berufswahl Rottal-Inn - Messematching',
    version: '2.0.0',
    locale: 'by',
  },
  theme: {
    isDark: false,
    primary: '#18adc9',
    accent: '#bacb2a',
    warn: '#f44336',
    useCompanyTheme: false,
    radar: null,
  },
  modules: {
    story: {
      disabled: false,
      mode: ContextMode.FAIR,
      session: '64d0e106b0348643d4c22bb9',
      location: null,
      radius: null,
      askLocation: false,
      askStartYear: false,
      askNogos: true,
      altCompletedMessage: null,
      placeholderQuestion: null,
    },
    browser: {
      disabled: true,
    },
    matching: {
      disabled: false,
      allowCompanyMatches: true,
      trackStats: false,
      useRoadmap: true,
      showRoadmapDownload: true,
      showAppDownload: false,
      askNickname: true,
      roadmapOnly: false,
      ratedTutorial: null,
      hideRestart: true,
      showApply: false,
      tutorial: null,
      links: [
        {
          text: 'Zum Hallenplan',
          url: 'https://www.berufswahl-rottal-inn.de/wp-content/uploads/Hallenplan-Ausstellerliste-2024.pdf',
          emphasise: true,
        },
        {
          text: 'Zum Abschlussquiz',
          url: 'https://kurse.berufswahl-rottal-inn.de/quizzes/quiz-berufe-matching/',
          emphasise: true,
          showWhen: () => {
            const isInIframe = parent !== window;
            if (isInIframe) {
              const parentUrl = document.referrer;
              return parentUrl.includes('kurse.berufswahl-rottal-inn.de');
            }
            return false;
          },
        },
      ],
      altDownloadButtonText: null,
      allowRegenerate: true,
      externalJobActions: [],
    },
    home: {
      disabled: false,
      auth: false,
      titleSuffix: 'Berufswahl Rottal-Inn 2024',
      passwords: ['Berufswahl2024'],
      imageSrc: null,
      welcomeMessageLines: [
        'Mit dem RECREWT Messe-Matching kannst du dich auf deinen bevorstehenden Messebesuch perfekt vorbereiten.',
        'Fülle den Fragebogen aus und lass dir von uns passende ausstellende Unternehmen mit deren Ausbildungsangeboten vorschlagen. ' +
          'Arbeite dich konzentriert durch deine persönlichen Aussteller-Matches und wähle deine Favoriten aus. ' +
          'So kannst du dir deinen ganz persönlichen Plan für die Messe erstellen.',
        'Gut vorbereitet und mit deinem Messeplaner in der Hand fällt ein Besuch am Messestand viel leichter und' +
          ' du kannst neben tollen Messegeschenken auch interessante Gespräche mit den ausstellenden Unternehmen führen.',
      ],
      footerImageSrc: 'assets/images/logo_www-berufswahl-rottal-inn-de_dblau.jpeg',
      fundedByImageSrc: 'assets/images/Logo_Regionalmanagement Kombi StMWi.jpeg',
      logoSrc: 'assets/images/rottal-inn.png',
      watermarkSrc: 'assets/images/rottal-inn-logo.png',
    },
  },
};
