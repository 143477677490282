import { createState, Store } from '@ngneat/elf';
import { withEntities } from '@ngneat/elf-entities';
import { Injectable } from '@angular/core';
import { EntityRepository } from '@data/entity-repository';
import { CompanyService } from '@data/company/company.service';
import { Company } from '@data/company/company.model';
import { withRequestsCache } from '@ngneat/elf-requests';

const { state, config } = createState(withEntities<Company>(), withRequestsCache());

const companyStore = new Store({ name: 'company', state, config });

@Injectable({ providedIn: 'root' })
export class CompanyRepository extends EntityRepository<Company> {
  protected constructor(companyService: CompanyService) {
    super(companyService);
  }

  protected override requireStore(): Store {
    return companyStore;
  }
}
