import { getActiveId, resetActiveId, selectActiveEntity, setActiveId } from '@ngneat/elf-entities';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EntityRepository } from '@data/entity-repository';

export abstract class ActiveEntityRepository<T> extends EntityRepository<T> {
  selectActive$: Observable<T> = this.requireStore().pipe(
    selectActiveEntity(),
    filter((x) => !!x),
  );

  getActiveId(): string | undefined {
    return this.store.query(getActiveId);
  }

  setActiveEntity(id: string | null) {
    this.store.update(setActiveId(id));
  }

  resetActiveEntity() {
    this.store.update(resetActiveId());
  }
}
