import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { Animations } from '@styles/animation/animations';
import { Subject } from 'rxjs';

@Component({
  selector: 'recrewt-story-guide',
  templateUrl: './story-guide.component.html',
  styleUrls: ['./story-guide.component.scss'],
  animations: [Animations.mascotWobble, Animations.mascotIn],
})
export class StoryGuideComponent implements OnInit {
  @Input() guidePosition: 'bottom-left' | 'center' | 'bottom-center' = 'bottom-left';

  @Input() desc = '';

  @Input() imageSrc: string | null = null;

  @Input() dismissable = true;

  @Input() hoverHide = true;

  @Input() wobbleSubject?: Subject<any>;

  @Input() buttonTemplate?: TemplateRef<any>;

  @Output() dismiss: EventEmitter<void> = new EventEmitter();

  showBubble = false;

  playWobble = false;

  dismissableOld = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.wobbleSubject?.subscribe(() => this.wobble());
  }

  wobble() {
    this.playWobble = !this.playWobble;
  }

  replayText() {
    this.wobble();
    const oldDesc = this.desc;
    this.desc = '';
    setTimeout(() => {
      this.desc = oldDesc;
    }, 0);
  }

  resetDismissable() {
    if (!this.dismissableOld) {
      return;
    }
    setTimeout(
      () => {
        this.dismissable = this.dismissableOld;
        this.dismissableOld = false;
        this.cdr.detectChanges();
      },
      this.dismissable ? 100 : 0,
    );
  }
}
