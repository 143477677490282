<div
  [class.pointer]="linkActive"
  [class.primary-bg-low]="!!match.markedForRoadmap"
  [routerLink]="linkActive ? match._id : null"
  class="mat-elevation-z6 surface card p-3 width-match-parent"
  fxLayout="column"
  fxLayoutAlign="stretch"
>
  <recrewt-gradient-icon-view
    [icon]="icon ?? ''"
    [src]="(companyLogo$ | async)?.blob | url"
    class="icon"
  ></recrewt-gradient-icon-view>
  <div class="status-icon-wrapper">
    <mat-icon *ngIf="match.status === MatchStatus.TRASH" class="material-icons gradient-trash">
      delete
    </mat-icon>
    <mat-icon *ngIf="match.status === MatchStatus.DISLIKE" class="material-icons gradient-dislike">
      thumb_down
    </mat-icon>
    <mat-icon *ngIf="match.status === MatchStatus.LIKE" class="material-icons gradient-like">
      thumb_up
    </mat-icon>
    <mat-icon
      *ngIf="match.status === MatchStatus.HIGHLIKE"
      class="material-icons gradient-high-like"
    >
      favorite
    </mat-icon>
  </div>
  <button
    (click)="markChange.emit(); $event.stopPropagation()"
    *ngIf="allowMarking"
    [title]="'TITLE.mark_for_roadmap' | translate"
    class="mark-icon"
    mat-icon-button
  >
    <mat-icon [color]="match.markedForRoadmap ? 'accent' : undefined" class="material-icons">
      {{ match.markedForRoadmap ? 'bookmark' : 'bookmark_border' }}
    </mat-icon>
  </button>
  <h5 class="no-pad mat-headline score m-t-3 text-align-center">
    {{ (match.score * 100).toFixed(0) }}%
  </h5>
  <p class="no-pad mat-subheading-1 job-name m-t-2" style="word-break: break-word">
    {{ jobName }}
  </p>
  <p *ngIf="specName as spec" class="no-pad on-surface-medium job-name m-t-1">
    {{ spec }}
  </p>
  <p *ngIf="desc" class="no-pad on-surface-medium job-name m-t-1">
    {{ desc }}
  </p>
  <div
    *ngIf="jobGeneral && linkActive && externalActions.length"
    class="m-t-2"
    fxFlex="grow"
    fxLayout="column"
    fxLayoutAlign="end center"
  >
    <ng-container *ngFor="let action of externalActions">
      <a
        (click)="$event.stopPropagation()"
        *ngIf="!action.showWhen || action.showWhen(jobGeneral)"
        [href]="jobGeneral | fn : action.action"
        class="job-button p-h-2 p-v-1 m-v-1 mat-elevation-z2"
        rel="noopener noreferrer"
        target="_blank"
      >
        {{ action.title }}
        <mat-icon class="no-pad p-l-s" inline>open_in_new</mat-icon>
      </a>
    </ng-container>
  </div>
  <div *ngIf="tag" class="m-t-4" fxFlex="grow" fxLayout="row">
    <mat-chip-list fxFlexAlign="end">
      <mat-chip class="chip" fxLayoutAlign="start center"
        ><mat-icon inline style="min-width: 24px">location_on</mat-icon>{{ tag }}</mat-chip
      >
    </mat-chip-list>
  </div>
</div>
