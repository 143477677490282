import {
  animate,
  keyframes,
  query,
  stagger,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const Animations = {
  fadeIn: trigger('fadeIn', [
    transition(':enter', [style({ opacity: 0 }), animate('0.3s ease-in', style({ opacity: 1 }))]),
  ]),
  fadeInOut: trigger('fadeInOut', [
    transition(':enter', [style({ opacity: 0 }), animate('0.3s ease-in', style({ opacity: 1 }))]),
    transition(':leave', [animate('0.3s ease-in', style({ opacity: 0 }))]),
  ]),
  mascotIn: trigger('mascotIn', [
    transition(':enter', [
      style({ transform: 'translateY(300%) scale(0.8, 1.8)' }),
      animate(
        '1s ease-in-out',
        keyframes([
          style({ transform: 'translateY(-80%) scale(1.2, 0.8)', offset: 0.3 }),
          style({ transform: 'scale(0.8, 1.2)', offset: 0.5 }),
          style({ transform: 'scale(1.2, 0.8)', offset: 0.7 }),
          style({ transform: 'scale(0.8, 1.2)', offset: 0.9 }),
          style({ transform: 'translateY(0) scale(1)', offset: 1 }),
        ]),
      ),
    ]),
  ]),
  mascotWobble: trigger('mascotWobble', [
    transition('false <=> true', [
      animate(
        '375ms ease-in-out',
        keyframes([
          style({ transform: 'scaleX(100%) scaleY(100%)', offset: 0 }),
          style({ transform: 'scaleX(115%) scaleY(85%)', offset: 0.3 }),
          style({ transform: 'scaleX(90%) scaleY(110%)', offset: 0.5 }),
          style({ transform: 'scaleX(95%) scaleY(105%)', offset: 0.8 }),
          style({ transform: 'scaleX(100%) scaleY(100%)', offset: 1 }),
        ]),
      ),
    ]),
  ]),
  staggeredSlideInTop: trigger('staggeredSlideInTop', [
    transition('* => *', [
      query(
        ':enter',
        [
          style({ transform: 'translateY(-33%)', opacity: 0 }),
          stagger(500, [
            animate('0.5s ease-out', style({ transform: 'translateY(0%)', opacity: 1 })),
          ]),
        ],
        { optional: true },
      ),
    ]),
  ]),
  slideInLeft: trigger('slideInLeft', [
    transition(':enter', [
      style({ transform: 'translateX(-200%)' }),
      animate('0.25s ease-in-out', style({ transform: 'translateX(0%)' })),
    ]),
  ]),
  slimOut: trigger('slimOut', [
    state('void', style({ height: '0px', opacity: 0 })),
    transition(':leave', [animate('0.25s linear', style({ height: '0px', opacity: 0 }))]),
  ]),
  bubbleGrow: trigger('bubbleGrow', [
    transition(':enter', [
      animate(
        '250ms ease-in-out',
        keyframes([
          style({ transform: 'scale(0)' }),
          style({ transform: 'scale(1.1)' }),
          style({ transform: 'scale(1)' }),
        ]),
      ),
    ]),
    transition(':leave', [
      animate(
        '250ms ease-in-out',
        keyframes([
          style({ transform: 'scale(1)' }),
          style({ transform: 'scale(1.1)' }),
          style({ transform: 'scale(0)' }),
        ]),
      ),
    ]),
  ]),
};
