<div (click)="openHref()" [class.pointer]="href" fxLayout="column" fxLayoutAlign="start center">
  <div
    [class.flat]="flat"
    [class.mat-elevation-z3]="!flat"
    [matTooltipDisabled]="!desc"
    [matTooltip]="desc ?? '' | translate"
    class="item surface"
    fxLayoutAlign="center center"
    matTooltipClass="tooltip"
  >
    <mat-icon *ngIf="!iconSet" [class.gradient-icon]="!flat">{{ icon }}</mat-icon>
    <mat-icon
      *ngIf="!!iconSet"
      [class.flat]="flat"
      [class.gradient-icon]="!flat"
      [fontIcon]="icon"
      [fontSet]="iconSet"
      class="fa-icon"
    ></mat-icon>
  </div>
  <p class="mat-caption text-align-center m-t-1">{{ title | translate }}</p>
</div>
