import { Component, OnInit } from '@angular/core';
import { appConfig } from '../../../app.config';

@Component({
  selector: 'recrewt-powered-by-layout',
  templateUrl: './powered-by-layout.component.html',
  styleUrls: ['./powered-by-layout.component.scss'],
})
export class PoweredByLayoutComponent implements OnInit {
  watermarkSrc =
    appConfig.modules.home.watermarkSrc ??
    appConfig.modules.home.imageSrc ??
    appConfig.modules.home.logoSrc;

  position = 'bottom-left';



  ngOnInit() {
    //if current page url contains story then position should be set to top-right
    if (window.location.href.includes('story')) {
      this.position = 'top-right';
    }

  }
}
