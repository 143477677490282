import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '@core/constants/system/lang';

@Component({
  selector: 'recrewt-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent implements OnInit {

  readonly languages = languages;
  language = languages[0];

  constructor(private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.changeLanguage(this.translate.currentLang);
  }

  changeLanguage(lang: string) {
    this.translate.use(lang);
    this.language = this.languages.find((lang) => lang.value === this.translate.currentLang) ?? languages[0];
  }
}
