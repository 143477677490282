<div
  (cdkDragMoved)="
    dismissableOld = dismissableOld ? dismissableOld : dismissable; dismissable = false
  "
  (cdkDragReleased)="resetDismissable()"
  [class.bottom-center]="guidePosition === 'bottom-center'"
  [class.bottom-left]="guidePosition === 'bottom-left'"
  [class.center]="guidePosition === 'center'"
  cdkDrag
  class="story-guide-wrapper grab"
  fxLayout="column"
  fxLayoutAlign="end end"
  ngClass.lt-md="bottom-left"
>
  <div class="p-l-3 p-b-s">
    <recrewt-auto-type-speech-bubble
      (dismiss)="dismiss.emit()"
      *ngIf="showBubble"
      [buttonTemplate]="buttonTemplate"
      [dismissable]="dismissable"
      [hoverHide]="hoverHide"
      [imageSrc]="imageSrc"
      [text]="desc | translate"
      class="pointer-events"
    ></recrewt-auto-type-speech-bubble>
  </div>
  <img
    (@mascotIn.done)="showBubble = true"
    (click)="!dismissable || dismiss.emit()"
    @mascotIn
    [@mascotWobble]="playWobble"
    alt="Albert"
    class="story-guide pointer-events"
    src="assets/images/albert.svg"
  />
</div>
