import { Component, Input } from '@angular/core';
import { SectionConfig } from '../section/section-config';

@Component({
  selector: 'recrewt-section-side-nav',
  templateUrl: './section-side-nav.component.html',
  styleUrls: ['./section-side-nav.component.scss'],
})
export class SectionSideNavComponent {
  @Input() sectionConfigs: SectionConfig[] = [];

  @Input() navtitle?: string;

  @Input() subtitle?: string;

  @Input() icon = 'business';
}
